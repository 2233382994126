//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      links: [
        { label: 'Work', action: () => this.scrollTo('.works') },
        { label: 'About', action: () => this.scrollTo('.about') },
        { label: 'Contact', action: () => this.scrollTo('.contact') },
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { header, headerContainer } = this.$refs
      const gsap = this.$gsap

      const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      // Scroll based animations
      if (!prefersReducedMotion)
        gsap.fromTo(
          headerContainer,
          { opacity: 1, scale: 1, yPercent: 0, filter: 'blur(0px)' },
          {
            opacity: 0,
            scale: 1.125,
            yPercent: -10,
            filter: 'blur(10px)',
            scrollTrigger: {
              scrub: 0.5,
              trigger: header,
              start: 'top+=15% top',
              end: 'bottom-=35%, top',
            },
          }
        )

      if (!prefersReducedMotion)
        gsap.fromTo(
          '.scroll-down',
          { opacity: 1 },
          {
            opacity: 0,
            scrollTrigger: {
              scrub: 0.75,
              trigger: header,
              start: 'top+=25% top',
              end: 'bottom-=25% top',
            },
          }
        )

      // MAIN Timeline
      const tl = gsap.timeline({
        paused: true,
        delay: 0.25,
      })

      tl.fromTo('.scroll-down', { opacity: 0 }, { opacity: 1 }, '<+0.75')
      tl.fromTo('.nav__sections__list__section', { opacity: 0 }, { opacity: 1, stagger: 0.05 }, '<+0.25')

      this.$nuxt.$on('show-layout', () => tl.play())
    },
    scrollTo(location) {
      const gsap = this.$gsap
      gsap.to(window, {
        scrollTo: {
          y: location,
        },
        duration: 2,
        ease: 'power3.inOut',
      })
    },
  },
}
